import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { Card, Button, Typography, Divider, Tooltip  } from 'antd';
import {LeftOutlined, RightOutlined, ExportOutlined  } from '@ant-design/icons'

type PDFViewerProps = {
  fileName: string | undefined;
  jobID: string
};

const PDFViewer: React.FC<PDFViewerProps> = ({ fileName, jobID }) => {
  const { Title } = Typography
  const CVURL = `${import.meta.env.VITE_API_URL}/get_pdf?file_name=${fileName}.pdf&jobID=${jobID}`
  const [numPages, setNumPages] = useState<number | null>();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages } : { numPages: number | null }) {
    setNumPages(numPages);
    setPageNumber(1); // Update the pageNumber state to 1 when the document is loaded
  }

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (numPages && pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const openCV = async () => {
    try {
      // Open the PDF in a new tab or window
      window.open(`${CVURL}&as_attachment=false`, '_blank');
    } catch (error) {
      // Handle any errors, e.g., show an error message
      console.error('Error opening PDF:', error);
    }
  }

  return (
    <Card>
      <div className='flex items-center justify-between w-full'>
        <Title level={2} className=''>CV</Title>
        <Button className='flex items-center justify-center' onClick={openCV}>
          <Tooltip title="Open CV in new window">
            <ExportOutlined />
          </Tooltip>
        </Button>
      </div>
      <Divider />
      <div className="flex justify-center">
        <Document file={CVURL} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} className={""} renderTextLayer={false} renderAnnotationLayer={false}/>
        </Document>
      </div>
      
      <div className="flex justify-center mt-3">
        <Button disabled={pageNumber === 1} onClick={handlePreviousPage} className='flex items-center justify-center'><LeftOutlined /></Button>
        <p className='mx-3 flex items-center'>
          {pageNumber} of {numPages}
        </p>
        <Button disabled={pageNumber === numPages} onClick={handleNextPage} className='flex items-center justify-center'><RightOutlined /></Button>
      </div>
    </Card>
  );
};

export default PDFViewer;