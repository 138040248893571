import React, { useRef } from "react";
import {
  Slider,
  Button,
  Input,
  InputNumber,
  Space,
  Upload as AntUpload,
  InputRef,
} from "antd";
import {
  DeleteOutlined,
  UploadOutlined,
  PlusOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { parse } from "papaparse";

type Keyword = {
  keyword: string;
  weight: number;
};

type KeywordsProps = {
  keywords: Keyword[];
  setKeywords: React.Dispatch<React.SetStateAction<Keyword[]>>;
  newKeyword: string;
  setNewKeyword: React.Dispatch<React.SetStateAction<string>>;
  showKeywordsInput: boolean;
  setShowKeywordsInput: React.Dispatch<React.SetStateAction<boolean>>;
};

const KeywordsWithSlider = ({
  keywords,
  setKeywords,
  newKeyword,
  setNewKeyword,
  showKeywordsInput,
  setShowKeywordsInput,
}: KeywordsProps) => {
  const inputRef = useRef<InputRef>(null);

  const handleSliderChange = (index: number, value: number) => {
    setKeywords((prevKeywords) => {
      const updatedKeywords = [...prevKeywords];
      updatedKeywords[index] = { ...updatedKeywords[index], weight: value };
      return updatedKeywords;
    });
  };

  const enableKeywordInput = () => {
    setShowKeywordsInput(true);
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim() !== "") {
      setKeywords((prevKeywords) => [
        ...prevKeywords,
        { keyword: newKeyword.trim(), weight: 1.0 },
      ]);
      setNewKeyword("");
    }
  };

  const handleDeleteKeyword = (index: number) => {
    setKeywords((prevKeywords) => {
      const updatedKeywords = [...prevKeywords];
      updatedKeywords.splice(index, 1);
      return updatedKeywords;
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleAddKeyword();
    }
  };

  const handleUploadKeywords = (file: Blob): boolean => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target?.result as string;
      parseCsv(text);
    };

    reader.readAsText(file);

    return false;
  };

  const parseCsv = (text: string) => {
    const results = parse(text, { header: true }).data;
    const keywords: Keyword[] = [];

    results.forEach((row: { Keyword: string; weight: number }) => {
      const updatedWeight =
        row["weight"] > 1 || row["weight"] < 0 ? 1 : row["weight"]; // If weight not between 0-1 set weight to 1.
      keywords.push({ keyword: row["Keyword"], weight: updatedWeight });
    });
    setKeywords((prevKeywords) => [...prevKeywords, ...keywords]);
  };

  const handleDownloadSampleCSV = () => {
    const csvData = "Keyword,weight\nCommunication,1.0\nLeadership,0.5";

    const blob = new Blob([csvData], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "keywordsUploadTemplate.csv";
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div className="px-3">
      <Button
        onClick={handleDownloadSampleCSV}
        id="tutorial-download-template"
        icon={<DownloadOutlined />}
      >
        Template CSV
      </Button>
      <AntUpload
        accept=".csv"
        showUploadList={false}
        maxCount={1}
        beforeUpload={(file) => handleUploadKeywords(file)}
      >
        <Button
          className="mb-5 ml-2"
          icon={<UploadOutlined />}
          id="tutorial-upload-keywords"
        >
          Upload CSV
        </Button>
      </AntUpload>

      {keywords.map((keyword, index) => (
        <div
          key={index}
          className="mb-2"
          id={index === 0 ? "tutorial-first-keyword-slider" : null}
        >
          <label className="text-lg font-bold">
            {keyword.keyword.charAt(0).toUpperCase() + keyword.keyword.slice(1)}
          </label>
          <div className="flex items-center my-2">
            <InputNumber
              min={0}
              max={1}
              step={0.1}
              value={keyword.weight}
              className="flex items-center justify-center"
              onChange={(value) => handleSliderChange(index, value as number)}
            />
            <Slider
              style={{ flex: 8, marginRight: "2rem", marginLeft: "2rem" }}
              min={0}
              max={1}
              step={0.1}
              marks={{ 0: "0", 1: "1" }}
              value={keyword.weight}
              onChange={(value) => handleSliderChange(index, value as number)}
            />
            <Button
              onClick={() => handleDeleteKeyword(index)}
              danger
              className="flex items-center justify-center"
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      ))}

      <div>
        {showKeywordsInput ? (
          <Space.Compact className="mt-2">
            <Input
              ref={inputRef}
              value={newKeyword}
              onChange={(e) => setNewKeyword(e.target.value)}
              placeholder="Enter keyword"
              onKeyDown={handleKeyDown}
            />
            <Button
              type="primary"
              onClick={handleAddKeyword}
              style={{ backgroundColor: "#389E0D", color: "white" }}
            >
              Add
            </Button>
          </Space.Compact>
        ) : (
          <Button
            className="mt-2 flex items-center"
            onClick={enableKeywordInput}
          >
            <PlusOutlined /> New Keyword
          </Button>
        )}
      </div>
    </div>
  );
};

export default KeywordsWithSlider;
