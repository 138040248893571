import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";

import "./App.css";

import { AuthProvider } from "./context/AuthProvider";
import MainLayout from "./layout/MainLayout";
import ControlPanel from "./pages/ControlPanel";
import Results from "./pages/Results";
import UploadJob from "./pages/UploadJob";
import Login from "./pages/Login";
import { theme } from "./components/theme";
import { ProtectedRoute } from "./components/ProtectedRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import { TutorialProvider } from "./context/tutorialProvider";

function App() {
  // const userLoggedIn = true;

  const router = createBrowserRouter([
    {
      element: (
        <AuthProvider>
          <ErrorBoundary>
            <MainLayout />
          </ErrorBoundary>
        </AuthProvider>
      ),
      children: [
        {
          path: "login",
          element: <Login />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthProvider>
          <ErrorBoundary>
            <TutorialProvider>
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            </TutorialProvider>
          </ErrorBoundary>
        </AuthProvider>
      ),
      children: [
        {
          path: "",
          element: <UploadJob />,
        },
        {
          path: "control-panel",
          element: <ControlPanel />,
        },
        {
          path: "results",
          element: <Results />,
        },
      ],
    },
  ]);

  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
