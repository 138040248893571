import { createContext, useContext, useState } from "react";

const TutorialContext = createContext({
  isTutorialToggled: false,
  setIsTutorialToggled: (_value: boolean) => {},
});

export function useTutorial() {
  return useContext(TutorialContext);
}

// Create a TutorialProvider component to wrap your application
export function TutorialProvider({ children }) {
  const [isTutorialToggled, setIsTutorialToggled] = useState(false);

  return (
    <TutorialContext.Provider
      value={{ isTutorialToggled, setIsTutorialToggled }}
    >
      {children}
    </TutorialContext.Provider>
  );
}
