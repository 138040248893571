import { useLocation, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Similarity from "../components/controlPanel/Similarity";
import Keywords from "../components/controlPanel/KeywordsWithSlider";
// import JobFields from '../components/controlPanel/JobFields';

import { Card, Button, Row, Col, Typography, Spin, message } from "antd";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { api } from "../config/api";

import { FieldWeights, KeywordWeights } from "../types";
import MinimumExperience from "../components/controlPanel/MinimumExperience";
import { driver } from "driver.js";
import { useTutorial } from "../context/tutorialProvider";

function ControlPanel() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const { state } = useLocation();
  const response = state?.response;
  const weightsPayload = state?.weightsPayload;
  const jobID = state?.jobID;
  const jobName = state?.jobName;

  const [loading, setLoading] = useState(false);
  const [similarityPercentage, setsimilarityPercentage] = useState<
    number | null
  >(50 || weightsPayload.similarityPercentage);
  const [keywordsPercentage, setkeywordsPercentage] = useState<number | null>(
    50 || weightsPayload.keywordsPercentage
  );
  const [jobFields] = useState<FieldWeights[]>(
    response.data.data?.fields || weightsPayload?.fields
  );
  const [keywords, setKeywords] = useState<KeywordWeights[]>(
    response.data.data?.keywords || weightsPayload?.keywords
  );
  const [newKeyword, setNewKeyword] = useState("");
  const [showKeywordsInput, setShowKeywordsInput] = useState<boolean>(false);
  const [minimumExperience, setMinimumExperience] = useState<number>(
    state?.minimumExperience || 0
  );
  const { isTutorialToggled, setIsTutorialToggled } = useTutorial();

  const handleSubmit = async () => {
    setLoading(true);

    // const keywordsWeightOne: KeywordWeights[] = keywords.map((keyword) => ({
    //     ...keyword,
    //     weight: 1,
    //   }));

    const payload = {
      similarityPercentage: similarityPercentage,
      keywordsPercentage: keywordsPercentage,
      fields: jobFields,
      keywords: keywords,
      minimumExperience,
      jobID,
    };

    try {
      const response = await api.post("/weights", payload);
      navigate("/results", {
        state: {
          response,
          weightsPayload: payload,
          jobID,
          jobName,
          minimumExperience,
        },
      });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      console.error("Upload error:", error);
    }
  };

  const tutorialTour = () => {
    const tutorial = driver({
      showProgress: true,
      stagePadding: 4,
      animate: true,

      steps: [
        {
          element: "#tutorial-download-template",
          popover: {
            title: "Download Template",
            description:
              "You can download the keywords template CSV file and add your keywords to it.",
            side: "left",
            align: "start",
          },
        },

        {
          element: "#tutorial-upload-keywords",
          popover: {
            title: "Upload Keywords",
            description:
              "You can upload a CSV file with the keywords and their weights.",
            side: "right",
            align: "start",
          },
        },
        {
          element: "#tutorial-first-keyword-slider",
          popover: {
            title: "Keyword Slider",
            description:
              "You can change the weight of the keywords by moving the slider, or remove unwanted keywords",
            side: "right",
            align: "start",
          },
        },
        {
          element: "#tutorial-minimum-experience",
          popover: {
            title: "Minimum Experience",
            description: "The minimum experience required for the job. All candidaes with less experience will be tagged.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#tutorial-scoring-criteria",
          popover: {
            title: "Scoring Criteria",
            description:
              "The scoring criteria for the CVs. You can change the weights of the keywords and the fields by moving the sliders.",
            side: "left",
            align: "start",
          },
        },
      ],
      onDestroyed: () => {
        setIsTutorialToggled(false);
        tutorial.refresh();
      },
    });

    tutorial.drive();
  };

  useEffect(() => {
    if (isTutorialToggled) {
      tutorialTour();
    }
  }, [isTutorialToggled]);

  return (
    <Card className="w-3/4 mb-10">
      <Row className="grid grid-cols-3 gap-4 items-center">
        <Col>
          <Link to="/">
            <ArrowLeftOutlined className="text-xl ml-2" />
          </Link>
        </Col>
        <Col>
          <Title className="text-center">
            Control Panel
            <Title
              level={2}
              className="text-center mt-2"
              id="tutorial-job-name"
            >
              Job: {jobID} - {jobName}
            </Title>
          </Title>
        </Col>
      </Row>
      {/* 
            <Title level={3}>Job Fields</Title>
            <Card className="my-5">
                <JobFields jobFields={jobFields} setJobFields={setJobFields}/>
            </Card > */}

      <Title level={3} id="tutorial-keywords">
        Keywords
      </Title>
      <Card className="my-5">
        <Keywords
          keywords={keywords}
          setKeywords={setKeywords}
          newKeyword={newKeyword}
          setNewKeyword={setNewKeyword}
          showKeywordsInput={showKeywordsInput}
          setShowKeywordsInput={setShowKeywordsInput}
        />
      </Card>

      <Title level={3}>Minimum Experience</Title>
      <Card className="my-5" id="tutorial-minimum-experience">
        <MinimumExperience
          minimumExperience={minimumExperience}
          setMinimumExperience={setMinimumExperience}
        />
      </Card>

      <Title level={3}>Scoring Criteria</Title>
      <Card className="my-5" id="tutorial-scoring-criteria">
        <Similarity
          similarityPercentage={similarityPercentage}
          setsimilarityPercentage={setsimilarityPercentage}
          keywordsPercentage={keywordsPercentage}
          setkeywordsPercentage={setkeywordsPercentage}
        />
      </Card>

      <Row justify="center">
        <Col>
          <Button
            type="primary"
            className="ml-3"
            onClick={handleSubmit}
            style={{ backgroundColor: "#389E0D", color: "white" }}
            id="tutorial-submit"
          >
            {loading ? (
              <>
                Loading{" "}
                <Spin
                  indicator={
                    <LoadingOutlined spin style={{ color: "white" }} />
                  }
                />
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
    </Card>
  );
}

export default ControlPanel;
