import { Keyword, BreakDownResponseData } from "../types";
import { Card, Spin, Typography, Table, Collapse, message } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { api } from "../config/api";
import PDFViewer from "../components/detailedResults/PDFViewer";

function DetailedResults({ cvId, jobID }: { cvId: string; jobID: string }) {
  const { Title } = Typography;
  const { Panel } = Collapse;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState<BreakDownResponseData | null>(null);

  // const jobFieldsColumns = [
  //   {
  //     title: 'Job Field',
  //     dataIndex: 'fieldName',
  //     key: 'fieldName',
  //     render: (text: string) => text.charAt(0).toUpperCase() + text.slice(1),
  //     sorter: (a: Field, b: Field) => a.fieldName.localeCompare(b.fieldName),
  //   },
  //   {
  //     title: 'Choice',
  //     dataIndex: 'choice',
  //     key: 'choice',
  //   },
  //   {
  //     title: 'Score',
  //     dataIndex: 'score',
  //     key: 'score',
  //     render: (score: number) => score.toFixed(2),
  //     sorter: (a: Field, b: Field) => a.score - b.score,
  //   }];

  const keywordsColumns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: (text: string) => text.charAt(0).toUpperCase() + text.slice(1),
      sorter: (a: Keyword, b: Keyword) => a.keyword.localeCompare(b.keyword),
    },
    {
      title: "Adjusted Weights",
      dataIndex: "maxScore",
      key: "maxScore",
      render: (maxScore: number) => maxScore.toFixed(2),
      sorter: (a: Keyword, b: Keyword) => a.maxScore - b.maxScore,
    },
    {
      title: "Keyword Detected",
      dataIndex: "score",
      key: "score",
      render: (score: number) =>
        score > 0 ? <CheckOutlined /> : <CloseOutlined />,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/breakdown/${cvId}`);
        setResponse(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("API error:", error);
        message.error("Something Went Wrong");
        setLoading(false);
        return <Card className="my-10 p-2"></Card>;
      }
    };

    fetchData();
  }, [cvId]);

  // const renderJobFields = (fields: Field[]) => {
  //   return (
  //     <Table
  //       dataSource={fields}
  //       columns={jobFieldsColumns}
  //       pagination={false}
  //     ></Table>

  //   );
  // };

  const renderKeywords = (keywords: Keyword[]) => {
    return (
      <Table
        dataSource={keywords}
        columns={keywordsColumns}
        pagination={false}
      ></Table>
    );
  };

  return (
    <Card className="my-10 p-2">
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Title>{`Detailed Results:`}</Title>
          <Title level={3} className="flex justify-between w-2/3">
            <span>Candidate ID# {response?.CVID.split("_")[0]}</span>
            <span>
              Total Score: {response?.totalScore.toFixed(2)} /{" "}
              {response?.maxTotalScore.toFixed(2)}
            </span>
          </Title>

          <Collapse className="my-10">
            <Panel header={<Title level={2}>Job Similarity</Title>} key="1">
              <Title level={4}>
                Score: {response?.jobSimilarity.score.toFixed(2)} /{" "}
                {response?.jobSimilarity.maxScore.toFixed(2)}
              </Title>
            </Panel>
          </Collapse>

          {/* <Collapse className='my-10'>
              <Panel header={<Title level={2}>Job Fields</Title>} key="1">
                {renderJobFields(response?.jobFields || [])}
              </Panel>
            </Collapse> */}

          <Collapse className="my-10">
            <Panel header={<Title level={2}>Keywords</Title>} key="1">
              <div className="m-2">
                <Title level={4}>
                  Score: {response?.keywordsScore.weightedScore.toFixed(2)} /{" "}
                  {response?.keywordsScore.maxWeightedScore.toFixed(2)}
                </Title>
              </div>
              {renderKeywords(response?.keyWords || [])}
            </Panel>
          </Collapse>

          <PDFViewer fileName={response?.CVID} jobID={jobID} />
        </>
      )}
    </Card>
  );
}

export default DetailedResults;
