import { useEffect, useState } from "react";
import {
  Button,
  Slider,
  Card,
  Row,
  Col,
  Typography,
  message,
  Spin,
  Select,
  Divider,
} from "antd";
// import UploadFile from './UploadFile';
// import UploadJobFields from './UploadJobFields';
import JobDescription from "./JobDescription";
import { LoadingOutlined } from "@ant-design/icons";
import { api } from "../../config/api";
import { useNavigate } from "react-router-dom";
import { jobPosts } from "../../constants";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { useTutorial } from "../../context/tutorialProvider";

//   fieldName: string;
//   choices: { choice: string; weight: number }[];
// };

type JobOption = {
  id: string;
  name: string;
};

const Upload = () => {
  const { Title } = Typography;

  const [selected, setSelected] = useState<any>();
  const [jobName, setJobName] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [jobDescription, setJobDescription] = useState("");
  const [jobPostsOptions] = useState<JobOption[]>(jobPosts);
  // const [fields, setFields] = useState<Field[]>([]);
  // const [fileContent, setFileContent] = useState<string>('')
  const [sliderValue, setSliderValue] = useState(1);
  const { isTutorialToggled, setIsTutorialToggled } = useTutorial();
  const navigate = useNavigate();

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
  };

  const handleSelectChange = async (value: string) => {
    const valuesArray = value.split(",");
    setJobName(valuesArray[1]);
    setSelected(valuesArray[0]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      jobID: selected,
      keywords: sliderValue,
      jobDescription: jobDescription,
      // fields: fields
    };

    try {
      const response = await api.post("/upload", payload);
      setLoading(false);
      navigate("/control-panel", {
        state: { response, uploadPayload: payload, jobID: selected, jobName },
      });
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
      console.error("Upload error:", error);
    }
  };

  const tutorialTour = () => {
    const tutorial = driver({
      showProgress: true,
      stagePadding: 4,
      animate: true,
      steps: [
        {
          element: "#tutorial-tool-container",

          popover: {
            title: "CV Ranking Tool",
            description:
              "A tool that Simplifies applicant evaluation by automatically ranking CVs, helping you focus on the top candidates effortlessly.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#tutorial-job-posts",
          popover: {
            title: "Job Posts",
            description: "Select the pre-configured job post you want to rank the CVs for.",
            side: "left",
            align: "start",
          },
        },
        {
          element: "#tutorial-key-words",
          popover: {
            title: "Number of keywords [experimental]",
            description:
              "The CV Ranking Tool can generate keywords from the job posts automatically. Select the number of keywords to be generated below",
            side: "left",
            align: "start",
          },
        },
      ],
      onDestroyed: () => {
        setIsTutorialToggled(false);
        tutorial.refresh();
      },
    });

    tutorial.drive();
  };

  useEffect(() => {
    if (isTutorialToggled) {
      tutorialTour();
    }
  }, [isTutorialToggled]);

  return (
    <Card className="driverjs-tour" id="tutorial-tool-container">
      <Title className="text-center">CV Ranking Tool</Title>
      <Card title="Job Posts" className="my-10" id="tutorial-job-posts">
        <div className="flex items-center">
          <Title level={5} className="mr-5">
            Job postings:{" "}
          </Title>
          <Select
            className="w-1/2"
            options={
              jobPostsOptions &&
              jobPostsOptions.map((jobPost) => ({
                value: `${jobPost.id},${jobPost.name}`,
                label: `${jobPost.id} - ${jobPost.name}`,
              }))
            }
            placeholder="Select Job post"
            size="large"
            onChange={handleSelectChange}
            allowClear
            value={jobName ? `${selected},${jobName}` : null}
          ></Select>
        </div>
      </Card>

      {!!selected ? (
        <>
          <JobDescription
            jobPostID={selected}
            jobDescription={jobDescription}
            setJobDescription={setJobDescription}
          />
        </>
      ) : (
        <>
          <Divider />

          {/* <UploadFile setFileContent={setFileContent}></UploadFile> */}

          {/* <UploadJobFields setFields={setFields}></UploadJobFields> */}
        </>
      )}

      <Card
        title="Number of keywords generated by AI"
        id="tutorial-key-words"
        className="my-5"
      >
        <p className="text-gray-600 text-sm mx-1 mb-10">
          The CV Ranking Tool can generate keywords from the job posts
          automatically. Select the number of keywords to be generated below
        </p>
        <Slider
          min={0}
          max={5}
          step={1}
          onChange={handleSliderChange}
          marks={{ 0: "0", 5: "5" }}
          value={sliderValue}
        />
      </Card>

      <Row justify="center">
        <Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ backgroundColor: "#389E0D", color: "white" }}
          >
            {loading ? (
              <>
                Loading{" "}
                <Spin
                  indicator={
                    <LoadingOutlined spin style={{ color: "white" }} />
                  }
                />
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Upload;
