import { Link } from "react-router-dom";
import logo from "../assets/pdo_black.png";
import profile from "../assets/profile.svg";
import { useAuth } from "../context/AuthProvider";
import { Button, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useTutorial } from "../context/tutorialProvider";

function Header() {
  const { logout, isAuthenticated, email } = useAuth();
  const { setIsTutorialToggled } = useTutorial();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button className="w-full" type="link" onClick={logout}>
          Logout
        </Button>
      ),
      className: "border text-center border-[#8C8C8C60]",
    },
  ];

  const toggleTutorial = () => {
    setIsTutorialToggled(true);
  };

  return (
    <div className="flex flex-row justify-between bg-gray-100 p-4 border-b-4 border-green-500">
      <Link to="/">
        <img src={logo} alt="dashboard logo" className="mr-4 ml-2" />
      </Link>

      <Button id="tutorial" className="text-md" onClick={toggleTutorial}>
        Tutorial
      </Button>
      {isAuthenticated ? (
        <Dropdown menu={{ items }}>
          <div className="flex flex-row items-center px-2">
            <img src={profile} alt="profile icon" />
            <h1 className="hidden sm:block text-md font-bold mx-4">{email}</h1>
            <CaretDownOutlined className="pl-2 sm:pl-0" />
          </div>
        </Dropdown>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Header;
