import React from "react";
import { InputNumber } from "antd";

type MinimumExperienceProps = {
  minimumExperience: number | null;
  setMinimumExperience: React.Dispatch<React.SetStateAction<number | null>>;
};

function MinimumExperience({
  minimumExperience,
  setMinimumExperience,
}: MinimumExperienceProps) {
  const handleMinimumExperienceChange = (value: number) => {
    setMinimumExperience(Math.round(value));
  };

  return (
    <div>
      <p className="text-gray-600 text-sm mx-3 mb-4">
        The candidates minimum required years of experience can be set to filter
        out inexperienced candidates.
      </p>
      <div className="mt-5">
        <label htmlFor="minimumExperience" className="text-lg mx-2">
          Minimum Experience:
        </label>
        <InputNumber
          id="minimumExperience"
          value={minimumExperience}
          min={0}
          step={1}
          onChange={handleMinimumExperienceChange}
        />
      </div>
    </div>
  );
}

export default MinimumExperience;
