export const jobPosts = [
  {
    id: "1282",
    name: "Snr HSE Coach",
  },
  {
    id: "1313",
    name: "HSE Coach",
  },
  {
    id: "1314",
    name: "HSE Advisor - Asset",
  },
  {
    id: "1316",
    name: "HSE Advisor - Wells",
  },
  {
    id: "1320",
    name: "HSE Advisor - Road Safety",
  },
  {
    id: "1321",
    name: "HSE Lead - Road Safety",
  },
  {
    id: "1322",
    name: "Digital HSE Advisor",
  },
  {
    id: "1323",
    name: "Snr. Behavioral Safety Adviser",
  },
  {
    id: "1324",
    name: "Digital HSE Lead",
  },
  {
    id: "1377",
    name: "Vendor Management Support",
  },
  {
    id: "1378",
    name: "Online Bidding Coordinator",
  },
  {
    id: "1406",
    name: "Senior Contract Engineer",
  },
  {
    id: "1407",
    name: "CP Projects Lead",
  },
  {
    id: "1411",
    name: "Contract Engineer",
  },
];
