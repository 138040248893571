import { Button, Form, Input, Card, message, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { useLogin } from "../hooks/useLogin";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const { mutate, isLoading } = useLogin(
    async (response) => {
      await login(response.data.email);
      await message.success("Logged in successfully", 2);

      navigate(`/`);
    },

    async (error) => {
      if (error?.response?.status === 401) {
        await message.error("Invalid email or password", 2);
      }
      await message.error("Something went wrong", 2);
    }
  );

  const onFinish = async (values: { email: string; password: string }) => {
    localStorage.setItem("email", values.email);
    mutate(values);
  };

  const validateEmail = (value: string, callback: any) => {
    if (value) {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

      if (!emailRegex.test(value)) {
        callback("Invalid email address");
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  return (
    <div className="w-screen min-h-screen bg-hero-pattern bg-no-repeat bg-cover flex flex-col gap-12 items-center mb-0">
      <Image
        src="pdo_black.png"
        width={257}
        height={53}
        preview={false}
        className="relative top-12 mb-12"
      />
      <div className="mt-12">
        <Card className="shadow-3xl pt-5 md:px-8 md:w-96 transition-all duration-300">
          <Form layout="vertical" onFinish={onFinish}>
            <div>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email Required",
                  },
                  {
                    validator: (_, value, callback) =>
                      validateEmail(value, callback),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password Required",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={isLoading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
