import { ThemeConfig } from 'antd';

export const theme : ThemeConfig = {
  token: {
    colorPrimary: "#389E0D",
    colorLink: "#389E0D",
    colorLinkHover: "#389E0D",
    colorText: "#000000",
    colorTextLightSolid: "#000000",
  },
  components: {
    Slider: {
      colorBgBase: "#389E0D",
    },
    Tooltip: {
      colorBgDefault: "#FFFFFF",
    },
    Descriptions: {
      fontSize: 18
    },
    Button: {
      colorText: "#389E0D",
    }
  },
}