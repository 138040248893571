import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Typography,
  Row,
  Col,
  Button,
  Checkbox,
  message,
  TableProps,
} from "antd";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/lib/table";
import { useState, useRef, useEffect } from "react";
import DetailedResults from "./DetailedResults";
import { Rank, WeightsResponse } from "../types";
import { api } from "../config/api";
import { useTutorial } from "../context/tutorialProvider";
import { driver } from "driver.js";

interface Filters {
  field: string;
  filters: string[];
}

function Results() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const { state } = useLocation();
  const response: WeightsResponse = state?.response.data;
  const weightsPayload = state?.weightsPayload;
  const jobID = state?.jobID;
  const jobName = state?.jobName;
  const minimumExperience = state?.minimumExperience;

  const [scores] = useState<Rank[]>(response.data);
  const [filters, setFilters] = useState<Filters[]>([]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const jobFields = response.jobFields;
  const [selectedCVId, setSelectedCVId] = useState<string | null>(null);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const detailedResultsRef = useRef<HTMLDivElement>(null);
  const { isTutorialToggled, setIsTutorialToggled } = useTutorial();
  let columns: ColumnType<Rank>[] = [
    {
      title: "Select",
      key: "select",
      render: (_: any, record: Rank) => (
        <Checkbox
          checked={selectedIDs.includes(record.CV_ID)}
          onChange={(e) => handleCheckboxChange(e, record.CV_ID)}
        />
      ),
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      // render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Candidate ID",
      dataIndex: "CV_ID",
      key: "Name",
      render: (CV_ID: string) => CV_ID.split("_")[0],
    },
    {
      title: "Application ID",
      dataIndex: "CV_ID",
      key: "CV_ID",
      render: (CV_ID: string) => CV_ID.split("_")[1],
    },
  ];

  for (const jobField of jobFields) {
    columns.push({
      title: jobField.title,
      dataIndex: jobField.title,
      key: jobField.title,
      filters: jobField.options.map((option) => ({
        text: option,
        value: option,
      })),
      onFilter: (value, record) => record[jobField.title] === value,
    });
  }

  columns.push(
    ...[
      {
        title: "Minimum Years Of Experience Met",
        dataIndex: "rejected",
        key: "rejected",
        render: (rejected: boolean) => (rejected ? "No" : "Yes"),
        filters: [
          { text: "Yes", value: false },
          { text: "No", value: true },
        ],
        onFilter: (value, record) => record.rejected === value,
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "details",
        render: (_: any, record: Rank) => (
          <a
            type="primary"
            onClick={() => handleRowClick(record.CV_ID)}
            className="text-green-500 hover:text-green-600"
          >
            View
          </a>
        ),
      },
    ]
  );

  const handleRowClick = async (cvId: string) => {
    try {
      setSelectedCVId(cvId);
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const handleCheckboxChange = (e: any, CV_ID: string) => {
    const isChecked = e.target.checked;
    setSelectedIDs((prevSelectedIDs) =>
      isChecked
        ? [...prevSelectedIDs, CV_ID]
        : prevSelectedIDs.filter((id) => id !== CV_ID)
    );
  };

  const downloadReport = async () => {
    setIsLoadingReport(true);
    const payload = {
      candidateIDs: selectedIDs,
      jobID: jobID,
      filters,
    };

    try {
      const response = await api.post("/retrieve_report", payload, {
        responseType: "blob", // Important for handling binary data
      });

      const currentDate = new Date()
        .toLocaleDateString()
        .replace(/[^\w\s]/g, "");
      const currentTime = new Date()
        .toLocaleTimeString()
        .replace(/[^\w\s]/g, "");

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobURL = URL.createObjectURL(blob);

      const tempLink = document.createElement("a");
      tempLink.href = blobURL;
      tempLink.setAttribute(
        "download",
        `${jobID}_${currentDate}_${currentTime}_report.xlsx`
      );
      tempLink.style.display = "none";

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      URL.revokeObjectURL(blobURL);
      setIsLoadingReport(false);
    } catch (error) {
      message.error("Error Downloading Report");
      console.error("Error:", error);
    }
  };

  const tableChange: TableProps<Rank>["onChange"] = (
    _pagination,
    filters,
    _sorter,
    _extra
  ) => {
    const chosenFilters = [];
    for (const key in filters) {
      if (filters[key] !== null) {
        chosenFilters.push({ field: key, filters: filters[key] });
      }
    }
    setFilters(chosenFilters);
  };

  const handleBackClick = () => {
    navigate("/control-panel", {
      state: { response, weightsPayload, jobID, jobName, minimumExperience },
    });
  };

  const tutorialTour = () => {
    const tutorial = driver({
      showProgress: true,
      stagePadding: 4,
      animate: true,
      steps: [
        {
          element: "#tutorial-data-table",
          popover: {
            title: "Results Table",
            description:
              "View the results of the job matching process. Click on a row to view the detailed results of a candidate.",
            side: "top",
            align: "start",
          },
        },
        {
          element: "#tutorial-data-table",
          popover: {
            title: "Shortlist candidate",
            description: "Shortlist your preferred candidates by checking the box, these tags will be available in the generated report.",
            side: "left",
            align: "center",
          },
        },
        {
          element: "#tutorial-data-table",
          popover: {
            title: "Filter Candidates",
            description: "You can filter the candidates based on their educational background, years of experience, or nationality.",
            side: "top",
            align: "center",
          },
        },
        {
          element: "#tutorial-data-table",
          popover: {
            title: "View Details",
            description: "You can further manually explore the CVs and spiedies candidate scores from the detailed results page.",
            side: "right",
            align: "center",
          },
        },
        {
          element: "#tutorial-download-report",
          popover: {
            title: "Download Report",
            description:
              "Download a report of the candidates, which includes their CVs, scores, and tags. While also excluding all the filtered-out candidates.",
            side: "left",
            align: "start",
          },
        },
      ],
      onDestroyed: () => {
        setIsTutorialToggled(false);
        tutorial.refresh();
      },
    });

    tutorial.drive();
  };

  useEffect(() => {
    if (isTutorialToggled) {
      tutorialTour();
    }
  }, [isTutorialToggled]);

  return (
    <div className="w-full mb-10">
      <Card className="mb-5">
        <Row className="grid grid-cols-3 gap-4 items-center">
          <Col>
            <ArrowLeftOutlined
              onClick={handleBackClick}
              className="text-xl ml-2"
            />
          </Col>
          <Col>
            <Title className="text-center">
              Results
              <Title level={2} className="text-center mt-2">
                Job: {jobID} - {jobName}
              </Title>
            </Title>
          </Col>
        </Row>

        <div className="w-full">
          <Button
            className="flex items-center ml-auto mb-2 mr-2"
            id="tutorial-download-report"
            style={{ backgroundColor: "#389E0D", color: "white" }}
            onClick={downloadReport}
            loading={isLoadingReport}
          >
            <DownloadOutlined /> Report
          </Button>
        </div>

        <div className="mx-auto overflow-x-auto">
          <Table
            id="tutorial-data-table"
            dataSource={scores}
            columns={columns}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.CV_ID),
            })}
            onChange={tableChange}
          />
        </div>
      </Card>

      {selectedCVId && (
        <div ref={detailedResultsRef}>
          <DetailedResults cvId={selectedCVId} jobID={jobID} />
        </div>
      )}
    </div>
  );
}

export default Results;
