import { Input, Card, message, Divider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { api } from "../../config/api";


const { TextArea } = Input;

interface JobDescriptionProps {
    jobPostID: string
    jobDescription: string
    setJobDescription: (content: string) => void;
}


const JobDescription: React.FC<JobDescriptionProps> = ({jobPostID,jobDescription, setJobDescription}) => {
    const [isFetching, setIsFetching] = useState(true)


    const fetchData = async () => {
        try {
            setIsFetching(true)
            const response = await api.get(`/jobDescription?jobID=${jobPostID}`);
            setJobDescription(response.data)            
            setIsFetching(false)
          } catch {
            message.error("Couldn't Retrieve Job Description");
            setJobDescription('')
            setIsFetching(false)
          }
     }   

    useEffect(() => {
        fetchData()
    },[jobPostID])

    const updateJobDescription = (event) => {
        const value = event.target.value;
        setJobDescription(value);
    }

    return (
        <>
            <Divider />

            <Card title="Job Description" className="my-10">

                {isFetching ? (
                    <Spin indicator={<LoadingOutlined spin/>} />
                ) : (
                    <>
                    <p className="text-gray-600 text-sm mx-1 mb-10">
                        Below is the job description text for your review. You can edit it for added clarity or context if needed. If it's already satisfactory, simply move to the next step without editing.
                    </p>
                    <TextArea
                        rows={15}
                        value={jobDescription}
                        onChange={updateJobDescription}
                    />
                    </>
                )}
            </Card>
        </>
    )
}

export default JobDescription