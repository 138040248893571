import { Layout, Button } from "antd";

function Footer() {
    return (
        <Layout.Footer style={{ textAlign: 'center' }}>
            CV Ranking Tool ©2023 Created by<Button type="link" href="https://phaze.ro" style={{padding: "5px"}}>PhazeRo</Button>
        </Layout.Footer>
    );
}

export default Footer;