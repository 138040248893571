import React from "react";
import { InputNumber } from "antd";

type SimilarityProps = {
  similarityPercentage: number | null;
  setsimilarityPercentage: React.Dispatch<React.SetStateAction<number | null>>;
  keywordsPercentage: number | null;
  setkeywordsPercentage: React.Dispatch<React.SetStateAction<number | null>>;
};

function Similarity({
  similarityPercentage,
  setsimilarityPercentage,
  keywordsPercentage,
  setkeywordsPercentage,
}: SimilarityProps) {
  const handleSimilarityPercentageChange = (value: number) => {
    setsimilarityPercentage(value);
    setkeywordsPercentage(100 - value);
  };

  const handleKeywordsPercentageChange = (value: number) => {
    setkeywordsPercentage(value);
    setsimilarityPercentage(100 - value);
  };

  return (
    <div>
      <p className="text-gray-600 text-sm mx-3 mb-4">
        The candidates are scored based on a similarity check between their CVs
        and the job description, as well as for the presence of keywords in
        their CVs. By default, both the similarity and keywords contribute equal
        portions to the final candidate score, however you can adjust their
        fractions from the screen below.
      </p>
      <div className="flex justify-around my-4 px-3">
        <div>
          <label htmlFor="similarity" className="text-lg mx-2">
            Similarity:
          </label>
          <InputNumber
            min={0}
            max={100}
            value={similarityPercentage}
            onChange={handleSimilarityPercentageChange}
            id="similarity"
          />
          <span style={{ marginLeft: "5px" }}>%</span>
        </div>

        <div>
          <label htmlFor="keywords" className="text-lg mx-2">
            Keywords:
          </label>
          <InputNumber
            min={0}
            max={100}
            value={keywordsPercentage}
            onChange={handleKeywordsPercentageChange}
            id="keywords"
          />
          <span style={{ marginLeft: "5px" }}>%</span>
        </div>
      </div>
    </div>
  );
}

export default Similarity;
